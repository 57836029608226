@import "../../styles/variables";
@import "../../styles/mixins";

.aboutsection {
  background-color: #f2f5f7;
  padding: 100px 0;
  overflow: hidden;

  h5 {
    font-size: 29px;
    font-weight: 600;
    color: $cOne;
  }

  h3 {
    font-weight: 600;
    font-size: 2.5rem;
  }

  &__box {
    margin-top: 25px;
    height: 100%;
    padding: 28px;
    text-align: center;
    background-color: #fff;

    svg {
      margin-bottom: 10px;
      font-size: 44px;
    }

    h6 {
      font-weight: 600;
      color: $cOne;
    }

    p {
      margin-bottom: 0;
    }
  }
}
