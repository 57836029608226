@import "../../styles/variables";
@import "../../styles/mixins";

.MuiBox-root {
    .MuiTab-root{

        font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"!important;
        font-weight: 600;
    }
}
