@import "../../styles/variables";
@import "../../styles/mixins";

.about {
  padding-top: 0;
  overflow: hidden;

  &__header {
    margin-top: -80px;
    background-image: url("../../resources/img/About/bg.jpg");
    background-position: top 50% right 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;

    .about__overlay {
      color: #fff;
      padding: 200px 0;
      margin-top: 80px;
      background: #01162783;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        font-size: 1.6rem;
      }

      h1 {
        font-size: 3.8rem;
      }

      h2 {
        font-size: 2.5rem;
      }

      p {
        max-width: 700px;
        font-size: 1.4rem;
        line-height: 1.3;
        margin-bottom: 50px;

        @include mq("phablet") {
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .about-counter {
    overflow: hidden;
    background-size: cover;
    background-image: url("../../resources/img/About/layout.jpg");

    &-layout {
      padding-bottom: 100px;

      h1 {
        padding: 40px 0 80px;
        color: #fff;
      }

      .col-md-4 {
        padding: 30px;
        display: flex;
        text-align: center;
        flex-direction: column;

        span {
          color: #ffffff;
          font-size: 35px;
          font-weight: 500;
          line-height: 40px;
        }

        h6 {
          font-size: 17px;
          color: #ffffff;
          font-weight: 400;
        }
      }
    }
  }
}

.about__administration > div {
  display: grid;

  .row {
    padding: 100px 0;

    & > div {
      padding: 0 30px;
      // max-height: 400px;
    }

    & img {
      object-fit: cover;
      object-position: 50% 30%;
      aspect-ratio: 3/2;
    }

    & h2 {
      font-size: 38px;
      font-weight: 600;
      margin-bottom: 50px;
      position: relative;

      @include mq("tablet-mid") {
        font-size: 32px;
      }

      @include mq("phablet") {
        font-size: 28px;
      }

      &::after {
        position: absolute;
        content: "";
        background-color: #aaaaaa;
        width: 10%;
        bottom: -30px;
        left: 0;
        height: 2px;
      }
    }

    & p:nth-child(2) {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;

      @include mq("tablet-mid") {
        font-size: 20px;
      }

      @include mq("phablet") {
        font-size: 18px;
      }
    }

    & p:nth-child(3) {
      font-size: 18px;
      margin-top: 15px;
      color: #55595c;
      max-width: 500px;

      @include mq("tablet-mid") {
        font-size: 17px;
      }

      &::before {
        content: "“";
        font-size: 100px;
        color: #e6e9ec;
        font-family: Times New Roman, Times, serif;
        font-weight: 900;
        line-height: 1;
        display: block;
        margin-bottom: -30px;
      }
    }
  }
}
