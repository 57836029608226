@import "./variables";

.button {
    color: #fff;
    border-radius: 8px;
    background-color: $cOne;
    margin-top: 25px;
    padding: 0.8em 2.2em;
    text-decoration: none;
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
    transition: 0.4s;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #000;
    }
}

// .button-fourth {
//     border-color: $cOne;
//     background: {
//         image: linear-gradient(45deg, $cOne 50%, transparent 50%);
//         position: 100%;
//         size: 400%;
//     }
//     // transition: background 200ms ease-in-out!important;

//     &:hover {
//         background-position: 0;
//     }
// }
