@import "../../styles/variables";
@import "../../styles/mixins";

.production {
  padding-top: 0;
  overflow: hidden;

  &__header {
    margin-top: -80px;
    background-position: top 70% right 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;

    .production__overlay {
      color: #fff;
      padding: 200px 0;
      margin-top: 80px;
      background: #01162783;
      display: flex;

      h1 {
        font-size: 3.8rem;
      }
    }

    &--wet-blue {
      background-image: url("../../resources/img/Production/bg.jpg");
    }

    &--krast {
      background-image: url("../../resources/img/Production/bg-krast.jpg");
    }

    &--leather {
      background-image: url("../../resources/img/Production/bg-leather.jpg");
    }
  }

  h2 {
    font-weight: 600;
    color: #131d3b;
    text-align: center;
    font-size: 2.5rem;
  }

  &__title {
    button {
      display: flex;
      margin: 28px auto 40px;
    }

    img {
      object-fit: cover;
      height: 500px;
      width: 100%;
    }
  }

  &__accordion {
    .col-md-6 {
    }
    &-box {
      padding: 15px;
      background-color: #f7f7f7;
    }
  }
  &__gallery {
    padding: 80px 0 100px;
    text-align: center;
    background-color: #f7f7f7;

    h5 {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: 600;
      color: $cOne;
    }

    h3 {
      margin-bottom: 15px;
      color: #131d3b;
      font-weight: 700;
      font-size: 2.5rem;
    }

    .SwiperGallery {
      padding: 0 45px;

      .swiper-button-next:after,
      .swiper-button-prev:after {
        font-size: 30px;
        font-weight: 600;
      }
    }
  }
}
