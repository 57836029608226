@import "../../styles/variables";
@import "../../styles/mixins";

.advantage {
    overflow: hidden;
    &__pics {
        .advantage__img {
            position: relative;
            margin-bottom: 100px;

            &-big {
                padding: 0 30px;
                max-height: 400px;
                object-fit: cover;
                width: 100%;
                margin-bottom: 55px;
            }

            &-small {
                box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 22%);
                width: 300px;
                height: 200px;
                object-fit: cover;
                bottom: -40px;
                right: -40px;
                position: absolute;
            }
        }

        h5,
        h3{
            padding-left: 40px;
        }

        h5{
            font-weight: 600;
            color: $cOne;
        }

        h3{
            font-weight: 600;
            font-size: 2.5rem;
        }

        p {
            padding: 10px 40px;
            color: #171717;
        }

        .button{
            transition: 0.4s!important;
            margin-left: 40px;
        }
    }

    iframe{
        margin-top: 80px;
        margin-bottom: 20px;
    }
}
