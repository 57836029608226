@import "../../styles/variables";
@import "../../styles/mixins";

.header {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  min-height: $menuHeight;
  padding: 15px 0;
  z-index: 9;
}

.menu {
  text-transform: capitalize;
  user-select: none;
  display: flex;
  align-items: center;

  li {
    flex-shrink: 0;
    list-style-type: none;
  }

  &__brand-img {
    height: 100px;
  }

  &__holding-img {
    height: 60px;
  }

  &__list {
    flex-grow: 1;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    align-items: baseline;
    margin: 0;
    text-transform: initial;
  }

  &__item {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.626);

    & > a,
    & > span {
      display: flex;
      color: white !important;
      justify-content: center;
      text-decoration: none;
      padding: 5px 0;
      position: relative;

      font-size: 18px;

      .menu--uz & {
        font-size: 16px;
      }

      &:hover::after,
      &.active:after {
        width: 70%;
        color: #fff;
      }

      &:after {
        width: 0;
        position: absolute;
        content: "";
        height: 2px;
        bottom: -1px;
        background: #fff;
        -o-transition: 0.5s;
        -ms-transition: 0.5s;
        -moz-transition: 0.5s;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
  }

  .nav-link {
    color: #fff !important;
    padding: 0;
  }

  .dropdown.item {
    .nav-link {
      padding: 5px;
    }
  }
  .dropdown-toggle::after {
    margin-left: 5px;
    vertical-align: 0.1em;
  }

  .dropdown-menu {
    background: transparent;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    display: block !important;
    top: 100% !important;
    transform: translate(30px, 2px) scale(1) !important;
    inset: unset !important;
    min-width: auto;
    // padding: 12px 20px 12px 12px;
    border: none;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16),
    //   0px 4px 18px rgba(0, 0, 0, 0.06);
    transition: all 0.2s $transition;

    .dropdown-item {
      color: #ccc;
      border-radius: 20px;
      padding: 3px 0px;
      background: transparent;

      &:hover {
        color: #fff;
      }
    }

    &.show {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 2px) scale(1) !important;
      inset: unset !important;
    }
  }

  &__lang {
    padding: 5px 0;
    color: rgba(255, 255, 255, 0.626);
    display: flex;
    align-items: center;

    font-size: 18px;

    .menu--uz & {
      font-size: 16px;
    }

    svg {
      margin-right: 7px;
    }

    .nav-item {
      transition: all 0.2s ease;

      &:hover {
        color: #fff;
      }

      &.active {
        color: #fff;
      }

      .nav-link {
        color: rgba(255, 255, 255, 0.626);
        position: relative;
        display: block;

        &::after {
          margin-left: 13px;
        }
      }
    }
  }

  &__hamburger {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 30px;
    z-index: 1;
    padding-left: 10px;
    margin-right: auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

    @include mq("tablet-mid") {
      margin-right: unset;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 15px;
    }

    span:nth-child(4) {
      top: 29px;
    }

    &.active {
      @include mq("tablet-small") {
        position: absolute;
        left: max(15px, 100% - 350px);
      }
    }

    &.active span:nth-child(1) {
      top: 15px;
      width: 0%;
      left: 50%;
    }

    &.active span:nth-child(2) {
      transform: rotate(45deg);
    }

    &.active span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &.active span:nth-child(4) {
      top: 15px;
      width: 0%;
      left: 50%;
    }
  }

  @include mq("tablet-mid") {
    & {
      justify-content: space-between;
    }

    .brand-name {
      display: none;
    }

    &__brand {
      display: none;
    }

    &__list {
      display: none;
    }

    &__lang {
      margin-left: auto;
    }
  }

  @include mq("tablet-mid", min) {
    &__hamburger {
      display: none;
    }
  }
}

.mob-menu {
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  transition: all 0.3s ease;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }

  &__content {
    background: #fff;
    box-shadow: 0 0 40px #00000040;
    position: relative;
    width: 300px;
    max-width: calc(100% - 65px);
    height: 100%;
    z-index: 1;
    transform: translateX(100%);
    transition: all 0.3s ease;
  }

  &__header {
    background-color: $cOne;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 10px 0 30px #00000069;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: $menuHeight;
  }

  &__body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - $menuHeight);
    margin-top: 10px;
    padding-bottom: 30px;
  }

  &__list {
    color: #000;
    padding: 0;
    margin: 0;
  }

  &__item {
    & > a {
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 1px;
      display: block;
      width: 100%;
      padding: 10px 15px;
      transition: all 0.2s ease;

      &::before {
        content: "[";
        right: 2px;
      }

      &::after {
        content: "]";
        top: 1px;
        left: 6px;
      }

      &::before,
      &::after {
        opacity: 0;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        transition: all 0.2s ease;
      }

      &.active {
        box-shadow: inset 10px 10px 20px #00000020, inset -10px -10px 20px #fff,
          0px 5px 15px #00000020;

        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__social {
    color: #fff;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 15px;

    a {
      line-height: 0;
    }

    & svg {
      margin: 0 0.5rem;
    }
  }

  &__lang {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      font-weight: 500;
      padding: 0.5rem 1rem;
      margin-top: 10px;
      border-radius: 30px;
      transition: all 0.3s ease;

      &.active {
        color: $cTwo;
        box-shadow: inset 10px 10px 20px #00000020, inset -10px -10px 20px #fff,
          0px 5px 15px #00000020;
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;

    .mob-menu__content {
      transform: translateX(0);
      transition-delay: 0.2s;
    }
  }
}

@include mq("phablet") {
  .menu__lang .dropdown-menu {
    inset: unset !important;
    padding: 10px;

    &.show {
      transform: translate(-15%, 10px) scale(1) !important;
    }
  }
}
