@import "../../styles/variables";
@import "../../styles/mixins";

.products {
  padding-top: 0;
  overflow: hidden;

  &__header {
    margin-top: -80px;
    background-image: url("../../resources/img/Production/products-bg-2.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;

    .products__overlay {
      color: #fff;
      padding: 200px 0;
      margin-top: 80px;
      background: #01162783;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 3.8rem;
      }

      h2 {
        font-size: 2.5rem;
      }
    }
  }
  .swiper-pagination {
    bottom: 0;
  }
  img {
    max-height: 425px;
    height: auto;
    object-fit: cover;
  }
}
