@import "../../styles/variables";
@import "../../styles/mixins";

.my-form {
  margin-top: 30px;

  &-right {
    padding: 30px 30px 0 30px;

    h6 {
      color: $cOne;
      margin-bottom: 10px;
    }

    h2 {
      font-weight: 600;
      font-size: 2.53rem;
      margin-bottom: 30px;
    }
  }

  .MuiInputLabel-root {
    color: #000 !important;
  }

  .Mui-focused,
  .MuiOutlinedInput-input,
  .MuiInput-input {
    background-color: #fafafa;
  }
}
