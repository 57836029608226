@import "../../styles/variables";
@import "../../styles/mixins";

.productsection {
    padding: 100px 0;
    background-color: $cOne;

    h4 {
        font-size: 28px;
        margin-bottom: 15px;
        color: #fff;
        text-align: center;
    }

    h2 {
        color: #fff;
        font-size: 2.5rem;
        margin-bottom: 20px;
        text-align: center;
    }

    &__box {
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;

        img {
            object-fit: cover;
        }

        &-parag {
            height: auto;
            
        }

        h3 {
            padding: 20px 20px 0 20px;
            font-size: 21px;
            margin-bottom: 15px;
            transition: 0.3s;

            &:hover {
                color: $cOne;
            }
        }

        p {
            padding: 0 20px 0 20px;
            line-height: 1.5em;
            font-size: 14px;
            color: #777;
        }

        .link-button {
            display: flex;
            align-items: flex-end;
            padding: 0 30px 20px;
            font-size: 14px;
            font-weight: 700;
            transition: 0.3s;

            &:hover {
                color: $cOne;
            }
        }
    }
}
