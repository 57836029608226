@import "../../styles/variables";
@import "../../styles/mixins";

.intro {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  background-color: #000000;

  #cf {
    width: 100vw;
    margin: 0 auto;
  }

  #cf img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    -webkit-animation-name: cf3FadeInOut;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 9s;
    animation-name: cf3FadeInOut;
    animation-iteration-count: infinite;
    animation-duration: 9s;
  }

  @-webkit-keyframes cf3FadeInOut {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    33% {
      opacity: 1;
    }
    53% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes cf3FadeInOut {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    33% {
      opacity: 1;
    }
    53% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .center {
    -webkit-animation-delay: -6s;
  }
  .bottom {
    -webkit-animation-delay: -3s;
  }

  .intro__overlay {
    color: #fff;
    padding: 180px 0;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 70px;
    }

    h2 {
      font-size: 2.5rem;
      line-height: 1.3;
    }

    p {
      max-width: 700px;
      font-size: 1.4rem;
      line-height: 1.3;
      margin-bottom: 50px;
    }

    a {
      width: 180px;
    }
  }
  @include mq("phablet") {
    .intro__overlay {
      padding-left: 10px;
      padding-right: 10px;

      h1 {
        font-size: 47px;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 14px;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }
}
