@import "../../styles/variables";
@import "../../styles/mixins";

.news {
  padding-top: 0;
  overflow: hidden;

  &__header {
    margin-bottom: 40px;
    margin-top: -80px;
    background-image: url("../../resources/img/News/bg.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;

    .news__overlay {
      color: #fff;
      padding: 200px 0;
      margin-top: 80px;
      background: #01162783;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        max-width: 650px;
        font-size: 1.6rem;
      }

      h1 {
        font-size: 3.8rem;
      }
    }
  }

  &__box {
    box-shadow: 0 0 29px -9px rgb(0 0 0 / 25%);
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;

    img {
      object-fit: cover;
    }

    &-parag {
      height: auto;
    }

    h3 {
      padding: 20px 20px 0 20px;
      font-size: 21px;
      margin-bottom: 15px;
      transition: 0.3s;
    }

    p {
      padding: 0 20px 0 20px;
      line-height: 1.5em;
      font-size: 14px;
      color: #777;
    }
    // .link-button {
    //     display: flex;
    //     align-items: flex-end;
    //     padding: 0 30px 20px;
    //     font-size: 14px;
    //     font-weight: 700;
    //     transition: 0.3s;

    //     &:hover {
    //         color: $cOne;
    //     }
    // }
  }
}
