@import "../../styles/variables";
@import "../../styles/mixins";

.investors {
  padding-top: 0;
  overflow: hidden;

  &__header {
    margin-top: -80px;
    background-image: url("../../resources/img/Investors/bg.jpg");
    background-position: bottom 0% center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;

    .investors__overlay {
      color: #fff;
      padding: 200px 0;
      margin-top: 80px;
      background: #01162783;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        max-width: 650px;
        font-size: 1.6rem;
      }

      h1 {
        font-size: 3.8rem;
      }
    }
  }

  &__parag {
    padding: 110px;

    .col-md-6 {
      padding-left: 0;
      margin: 30px 0;
      border-bottom: 1px solid #131d3b;
    }

    h4 {
      width: 90%;
      margin-bottom: 20px;
      font-size: 1.6rem;
      font-weight: 500;
      color: #131d3b;
    }

    p {
      width: 90%;
    }
  }

  &__callback {
    padding-bottom: 200px;
    background-image: url("../../resources/img/About/9E4A0991.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;

    &-box {
      width: 680px;
      padding: 25px 50px 50px;
      display: inline-block;
      background-color: #ffffff7a;
      margin: 0 auto;

      h6 {
        font-size: 1.6rem;
        color: #000;
        margin-bottom: 10px;
      }

      h3 {
        color: #131d3b;
        font-size: 2.2rem;
        line-height: 1.3;
      }

      p {
        font-weight: 500;
        font-size: 19px;
        margin-bottom: 30px;
        line-height: 1.85;
      }
    }
  }
}
