@import "../../styles/variables";
@import "../../styles/mixins";

.contacts {
  padding-top: 0;
  overflow: hidden;

  &__header {
    margin-top: -80px;
    background-image: url("../../resources/img/Contacts/bg.jpg");
    background-position: top 100% right 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;

    .contacts__overlay {
      color: #fff;
      padding: 200px 0;
      margin-top: 80px;
      background: #01162783;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        font-size: 1.6rem;
      }

      h1 {
        font-size: 3.8rem;
      }

      h2 {
        font-size: 2.5rem;
      }
    }
  }

  &__box {
    height: 100%;
    box-shadow: 0px 0px 20px 0px rgb(206 206 206 / 50%);
    padding: 20px;

    h5 {
      display: flex;
      align-items: center;
      font-size: 23px;
      margin-bottom: 30px;

      &::before {
        background-color: $cOne;
        content: "";
        display: block;
        height: 1.5px;
        margin-right: 15px;
        width: 40px;
      }
    }

    svg {
      font-size: 30px;
      color: $cOne;
      margin-right: 15px;
    }

    h6 {
      font-size: 1rem;
      margin-bottom: 8px;
      color: #131d3b;
    }

    p {
      color: #171717;
    }
  }

  iframe {
    margin-top: 110px;
  }

  @include mq("phone-wide") {
    &__header {
      .contacts__overlay {
        h1 {
          font-size: 50px;
        }
      }
    }
  }
}
